import React from 'react';
import { Box, Typography } from '@mui/material';
import { IAuthenticationPageContent } from 'backend/types/authenticationPageContent';
import IconWithText from 'components/icon/IconWithText';
import backgroundImage from 'assets/img/authentication_page_img.svg';
import CallIcon from '@mui/icons-material/Call';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { getImagePath, handleOnImageError } from 'util/helpers/imageHelpers';

interface ISideInformationPanelProps {
    careSiteExternalId: string | undefined;
    authenticationPageContent: IAuthenticationPageContent;
}

const styles = {
    container: {
        height: '100%',
        backgroundColor: 'primary.dark',
        backgroundImage: 'url(' + backgroundImage + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right',
    },
    innerContainer: {
        pl: 5,
        color: 'background.default',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    welcomeHeader: { fontSize: '52px', fontWeight: 500, color: 'inherit', marginBlock: '0.83em' },
    contacts: {
        mt: 5,
        mb: 2,
        '& > .MuiTypography-root': {
            color: 'background.default',
            '&:not(:last-child)': {
                mb: 2,
            },
        },
    },
    logo: { margin: 'auto 0', maxWidth: '90%' },
} as const;

const SideInformationPanel = ({ careSiteExternalId, authenticationPageContent }: ISideInformationPanelProps) => {
    const year = new Date().getFullYear();
    return (
        <Box sx={styles.container}>
            <Box sx={styles.innerContainer}>
                <Box flexGrow="1">
                    <Box pt={8}>
                        {authenticationPageContent?.images?.logo && (
                            <img
                                style={styles.logo}
                                src={getImagePath(authenticationPageContent.images.logo, careSiteExternalId)}
                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleOnImageError(e, authenticationPageContent.images.logo)}
                            />
                        )}
                    </Box>
                    <Box pt={2}>
                        <Typography variant="h2" component="h2" sx={styles.welcomeHeader}>
                            {authenticationPageContent?.sideInformation?.title || 'Welcome to Member Portal'}
                        </Typography>
                    </Box>
                    <Box dangerouslySetInnerHTML={{ __html: authenticationPageContent?.sideInformation?.descriptionHtml || '' }}></Box>
                </Box>
                <Box sx={styles.contacts}>
                    <Typography variant="h2">Need Help?</Typography>
                    <Typography variant="body1">
                        <IconWithText icon={<CallIcon />} text={authenticationPageContent?.sideInformation?.contactPhone} />
                    </Typography>
                    <Typography variant="body1">
                        <IconWithText icon={<WatchLaterIcon />} text={authenticationPageContent?.sideInformation?.workHours} />
                    </Typography>
                    <Typography variant="body1" mt={5}>
                        © {year} Advantasure Inc. All Rights Reserved.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default SideInformationPanel;
