export function getImagePath(name: string | undefined, careSiteExternalId: string | undefined = undefined) {
    if ((name == undefined || name == null || name == '') && (careSiteExternalId == undefined || careSiteExternalId == null || careSiteExternalId == '')) {
        return '';
    }

    return careSiteExternalId != undefined
        ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_ASSET_DIR}/${careSiteExternalId}/${name}`
        : `${process.env.PUBLIC_URL}/${process.env.REACT_APP_ASSET_DIR}/${name}`;
}

export function handleOnImageError(e: React.SyntheticEvent<HTMLImageElement, Event>, logo?: string) {
    if (e.currentTarget.getAttribute('showDefault') == 'true') {
        e.currentTarget.removeAttribute('src');
        e.currentTarget.removeAttribute('showDefault');
    } else if (e.currentTarget.getAttribute('src') != '') {
        e.currentTarget.setAttribute('showDefault', 'true');
        e.currentTarget.src = getImagePath(logo);
    }
}
