/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefinitionNode, Kind, ListTypeNode, NonNullTypeNode, OperationDefinitionNode, TypeNode } from 'graphql';
import { ApolloLink } from '@apollo/client';
import { isValidDate, toIsoDateStringOrNull } from './dateTimeHelpers';

function isOperationDefinitionNode(node: DefinitionNode): node is OperationDefinitionNode {
    return node.kind === Kind.OPERATION_DEFINITION;
}

function isListTypeNode(node: TypeNode): node is ListTypeNode {
    return node.kind === Kind.LIST_TYPE;
}

function isNonNullTypeNode(node: TypeNode): node is NonNullTypeNode {
    return node.kind === Kind.NON_NULL_TYPE;
}

function isObject(o: any) {
    return Object.prototype.toString.call(o) === '[object Object]';
}

export function isPlainObject(o: any) {
    if (!isObject(o)) return false;

    // If object has modified constructor
    const ctor = o.constructor;
    if (ctor === undefined) return true;

    // If object has modified prototype
    const prot = ctor.prototype;
    if (!isObject(prot)) return false;

    // If constructor does not have an Object-specific method
    // eslint-disable-next-line no-prototype-builtins
    if (!prot.hasOwnProperty('isPrototypeOf')) {
        return false;
    }

    // Most likely a plain Object
    return true;
}

function formatObject(value: any) {
    const copy = { ...value };
    const keys = Object.keys(copy);
    for (const key of keys) {
        const value = copy[key];
        if (value != null) {
            if (isValidDate(value)) {
                copy[key] = toIsoDateStringOrNull(value);
            } else if (isPlainObject(value)) {
                copy[key] = formatObject(value);
            }
        }
    }

    return copy;
}

function formatValue(value: any, typeNode: TypeNode): any {
    if (isNonNullTypeNode(typeNode)) {
        typeNode = typeNode.type;
    }

    if (typeNode.kind == Kind.NAMED_TYPE) {
        if (isValidDate(value)) {
            const typeName = typeNode.name.value;
            if (typeName === 'Date') {
                return toIsoDateStringOrNull(value);
            }
        } else if (isPlainObject(value)) {
            return formatObject(value);
        }
    } else if (isListTypeNode(typeNode)) {
        return formatArray(value, typeNode.type);
    }

    return value;
}

function formatArray(value: any, elementType: TypeNode): any {
    if (!Array.isArray(value)) {
        return value;
    }
    return value.map((s) => formatValue(s, elementType));
}

export const dateFormatLink = new ApolloLink((operation, forward) => {
    const o = operation.query.definitions.find(isOperationDefinitionNode);
    const varDefs = o?.variableDefinitions || [];
    varDefs.forEach((vd) => {
        const key = vd.variable.name.value;
        const value = operation.variables[key];

        operation.variables[key] = formatValue(value, vd.type);
    });
    return forward(operation);
});
