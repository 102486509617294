import { Dispatch, Middleware } from 'redux';
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, logoutSuccess, loginSuccess } from './actions';
import { gql } from '@apollo/client';
import apolloClient from 'util/apolloClient';
import { AccountResultStatusCode, IAccountResult } from 'backend/types/accountTypes';
import * as H from 'history';
import { RootState } from '../index';
import { toast } from 'react-toastify';
import { IAuthUser } from './types';

const LOGOUT_ACCOUNT = gql`
    mutation logout {
        account {
            logout {
                status
            }
        }
    }
`;

const INFORMATION_ACCOUNT = gql`
    query information {
        account {
            information {
                familyName
                givenName
                email
            }
        }
    }
`;

interface ILogoutData {
    account: {
        logout: IAccountResult;
    };
}

export const loginMiddleware: Middleware<unknown, RootState> = (storeAPI) => (next) => (action) => {
    const result = next(action);

    if (action.type === LOGIN_SUCCESS) {
        const state = storeAPI.getState();
        if (state.account?.currentUser) {
            localStorage.setItem('authUser', JSON.stringify(state.account.currentUser));
        }
    } else if (action.type === LOGOUT_SUCCESS) {
        localStorage.removeItem('authUser');
    }

    return result;
};

export const login = (history: H.History, email: string | undefined) => {
    return async (dispatch: Dispatch): Promise<void> => {
        let firstName = undefined;
        let lastName = undefined;
        try {
            const response = await apolloClient.query({
                query: INFORMATION_ACCOUNT,
                fetchPolicy: 'no-cache',
            });

            if (response.errors) {
                throw response.errors;
            }
            firstName = response.data.account?.information?.givenName;
            lastName = response.data.account?.information?.familyName;
            email = response.data.account?.information?.email;
        } catch (error) {
            console.log(error);
            toast.error('Internal error while fetching user details');
        }

        const responseData = { email: email, firstName: firstName, lastName: lastName } as IAuthUser;
        dispatch(loginSuccess(responseData));
        history.push('/');
    };
};

export const logout = (history: H.History) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const response = await apolloClient.mutate({
            mutation: LOGOUT_ACCOUNT,
            fetchPolicy: 'no-cache',
        });

        if (response.errors) {
            toast.error('An internal error occurred during logout.');
            return;
        }

        const responseData = response.data as ILogoutData;
        if (responseData.account.logout.status == AccountResultStatusCode.Ok) {
            dispatch(logoutSuccess());
            history.push(`/account/login`);
        }
    };
};

export const handleExpiredSession = (dispatch: Dispatch) => {
    dispatch(logoutSuccess());
    window.location.href = `/account/login`;
};
