import { Dispatch } from 'redux';
import { initialLoadReceived, initialAuthenticationPageLoadReceived, careSiteResolved } from './action';
import apolloClient from 'util/apolloClient';

import { IPageContent } from 'backend/types/pageContent';
import { gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { IAuthenticationPageContent } from 'backend/types/authenticationPageContent';

const GET_PAGE_CONTENT = gql`
    query IPageContent {
        pageContent {
            jsonContent
            careSite {
                externalId
            }
        }
    }
`;

export const initialLoad = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        apolloClient
            .query({
                query: GET_PAGE_CONTENT,
            })
            .then((response) => {
                const pageContent = JSON.parse(response.data.pageContent.jsonContent) as IPageContent;
                pageContent.careSiteExternalId = response.data.pageContent.careSite.externalId;
                dispatch(initialLoadReceived(pageContent));
            })
            .catch((error) => {
                console.log(error);
                toast.error('Internal error while getting page content');
            });
    };
};

const GET_AUTHENTICATION_PAGE_CONTENT = gql`
    query IAuthenticationPageContent($careSiteExternalId: String!) {
        authenticationPageContent(careSiteExternalId: $careSiteExternalId) {
            jsonContent
        }
    }
`;

const GET_CARE_SITE_EXTERNAL_ID = gql`
    query getCareSiteExternalId($url: String!) {
        careSiteExternalId: getCareSiteExternalId(url: $url)
    }
`;

export const initialAuthenticationPageLoad = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            const {
                data: { careSiteExternalId },
            } = await apolloClient.query({
                query: GET_CARE_SITE_EXTERNAL_ID,
                variables: {
                    url: window.location.href,
                },
            });
            dispatch(careSiteResolved(careSiteExternalId));

            const {
                data: { authenticationPageContent },
            } = await apolloClient.query({
                query: GET_AUTHENTICATION_PAGE_CONTENT,
                variables: {
                    careSiteExternalId: careSiteExternalId,
                },
            });
            const pageContent = JSON.parse(authenticationPageContent.jsonContent) as IAuthenticationPageContent;
            dispatch(initialAuthenticationPageLoadReceived(pageContent));
        } catch (error) {
            console.log(error);
            toast.error('Internal error while getting page content');
        }
    };
};
