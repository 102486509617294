import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import globalStyles from 'config/cssHooks';

interface IVerticalBarProps {
    sx?: SxProps<Theme> | undefined;
}

const VerticalBar = ({ sx }: IVerticalBarProps) => {
    const sxProps = sx ?? {};
    return <Box component="div" sx={{ ...globalStyles.verticalBar, ...sxProps }}></Box>;
};

export default VerticalBar;
