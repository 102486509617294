import Header from 'components/header';
import Footer from 'components/footer';
import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface IPageWrapperProps {
    children: ReactNode;
}

const PageWrapper = ({ children }: IPageWrapperProps) => {
    return (
        <Box component="div" sx={{ height: '100%', margin: 0, display: 'flex', flexDirection: 'column' }}>
            <Header />
            {children}
            <Footer />
        </Box>
    );
};

export default PageWrapper;
