import React, { ComponentType, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import AuthenticationPageWrapper from 'templates/AuthenticationPageWrapper';
import { initialAuthenticationPageLoad } from 'store/environment/middleware';

interface IAuthenticationRouteProps {
    targetComponent: ComponentType<RouteComponentProps> | ComponentType;
    path: string;
    exact: boolean;
}
const AuthenticationRoute = ({ targetComponent: Component, ...rest }: IAuthenticationRouteProps) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initialAuthenticationPageLoad());
    }, [dispatch]);
    return (
        <Route
            {...rest}
            render={(props) => (
                <AuthenticationPageWrapper>
                    <Component {...props} />
                </AuthenticationPageWrapper>
            )}
            exact
        />
    );
};

export default AuthenticationRoute;
