import { createTheme } from '@mui/material/styles';
import { COLORS } from 'consts/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

let theme = createTheme({
    palette: {
        primary: {
            light: COLORS.PALE_SKY_BLUE,
            main: COLORS.SYMPHONY_BLUE,
            dark: COLORS.SYMPHONY_DARKBLUE,
        },
        secondary: {
            light: COLORS.LIGHT_GREY,
            main: COLORS.ICE_GREY,
            dark: COLORS.INFORMATION_GREY,
        },
        success: {
            light: COLORS.LIGHT_GREEN,
            main: COLORS.GREEN,
        },
        warning: {
            light: COLORS.LIGHT_YELLOW,
            main: COLORS.ORANGE,
        },
        error: {
            light: COLORS.LIGHT_RED,
            main: COLORS.RED,
        },
    },
});

theme = createTheme(theme, {
    typography: {
        fontSize: 14,
        fontFamily: 'Roboto',
        h1: {
            fontSize: 72,
            color: theme.palette.text.primary,
            lineHeight: 1.22,
            fontWeight: 100,
        },
        h2: {
            fontSize: 24,
            lineHeight: 1.25,
            letterSpacing: 0,
            fontWeight: 500,
            color: theme.palette.text.primary,
        },
        h3: {
            fontSize: 16,
            color: theme.palette.text.primary,
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: 'normal',
            borderRadius: '4px 4px 0px 0px',
        },
        h4: {
            fontSize: 16,
            color: theme.palette.secondary.dark,
            fontWeight: 300,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
        h5: {
            fontSize: 32,
            fontWeight: 400,
            letterSpacing: 0.5,
            color: theme.palette.text.primary,
        },
        h6: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.14,
            letterSpacing: 0.8,
            color: theme.palette.primary.main,
        },
        body1: {
            fontSize: 14,
            color: theme.palette.text.primary,
            lineHeight: 1.71,
        },
        body2: {
            fontSize: 12,
            color: theme.palette.text.primary,
            lineHeight: 2,
        },

        subtitle1: {
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 0,
        },
        subtitle2: {
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: 0,
        },
        button: {
            fontSize: 14,
            lineHeight: 1.4,
            color: theme.palette.text.primary,
            letterSpacing: 0.5,
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.background.default,
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        backgroundColor: theme.palette.background.default,
                    },
                },
                {
                    props: { variant: 'contained' },
                    style: {
                        minWidth: '127px',
                    },
                },
            ],
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                size: 'small',
                margin: 'dense',
                fullWidth: true,
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'outlined',
                size: 'small',
                margin: 'dense',
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    marginBottom: 0,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'outlined',
                size: 'small',
                margin: 'dense',
            },
        },
        MuiGrid: {
            defaultProps: {
                spacing: 2,
            },
        },
        MuiDataGrid: {
            defaultProps: {
                autoHeight: true,
                disableColumnFilter: true,
                density: 'compact',
                pageSize: 25,
            },
            styleOverrides: {
                root: {
                    border: 0,
                    marginTop: theme.spacing(2),
                    width: '100%',
                },
                columnSeparator: {
                    visibility: 'hidden',
                },
                columnHeader: {
                    '&.header-class': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.dark,
                        fontSize: 'larger',
                    },
                },
                columnHeaders: {
                    zIndex: 1,
                },
                cell: {
                    '&.cell-pointer-class': {
                        cursor: 'pointer',
                    },
                    '>.styledMuiDataGridCell': {
                        padding: '0px 8px',
                        borderRadius: 4,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                    '>.muiDataGridCellSuccess': {
                        backgroundColor: theme.palette.success.light,
                    },
                    '>.muiDataGridCellWarning': {
                        backgroundColor: theme.palette.warning.light,
                    },
                    '>.muiDataGridCellError': {
                        backgroundColor: theme.palette.error.light,
                    },
                },
                columnHeaderTitleContainer: {
                    padding: 0,
                },
            },
        },
        MuiLink: {
            defaultProps: {
                color: theme.palette.primary.main,
                underline: 'none',
                fontWeight: '500',
            },
        },
    },
});

export default theme;
