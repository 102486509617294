import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CONFIRM_ACCOUNT,
    CONFIRM_ACCOUNT_SUCCESS,
    FORGOT_EMAIL_SUCCESS,
    FORGOT_PASSWORD_SUCCESS,
    IAccountActions,
    LOGIN_MFA,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    MEMBER_NOT_REGISTERED,
} from './actions';
import { IAccountState, IAuthUser } from './types';

export const initialState: IAccountState = getInitialState();

function getInitialState() {
    const lsUser = localStorage.getItem('authUser');
    let user = {} as IAuthUser;
    let isLoggedIn = false;
    if (lsUser) {
        user = JSON.parse(lsUser) as IAuthUser;
        isLoggedIn = true;
    }

    return {
        currentUser: user,
        isLoggedIn: isLoggedIn,
        error: '',
    };
}

const accountReducer = (state: IAccountState = initialState, action: IAccountActions): IAccountState => {
    switch (action.type) {
        case CONFIRM_ACCOUNT:
            return {
                ...state,
                error: '',
                email: action.email,
                isConfirmed: false,
                registerMemberDetails: undefined,
            };
        case CONFIRM_ACCOUNT_SUCCESS:
            return {
                ...state,
                error: '',
                isConfirmed: true,
            };
        case LOGIN_MFA:
            return {
                ...state,
                error: '',
                email: action.email,
                token: action.token,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                error: '',
                currentUser: action.user ? action.user : ({} as IAuthUser),
                isLoggedIn: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                error: '',
                isLoggedIn: false,
                currentUser: {} as IAuthUser,
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                error: '',
                email: action.email,
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                error: '',
                email: action.email,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                error: '',
                email: action.email,
            };
        case MEMBER_NOT_REGISTERED:
            return {
                ...state,
                error: '',
                registerMemberDetails: action.memberDetails,
            };
        case FORGOT_EMAIL_SUCCESS:
            return {
                ...state,
                error: '',
                registerMemberDetails: undefined,
                email: action.email,
            };
        default:
            return state;
    }
};

export default accountReducer;
