import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import environmentReducer from './environment/reducer';
import { IEnvironmentState } from './environment/types';
import { IHeaderState } from './header/types';
import headerReducer from './header/reducer';
import { IAccountState } from './account/types';
import accountReducer from './account/reducer';
import { loginMiddleware } from './account/middleware';

export interface IState {
    environment: IEnvironmentState;
    header: IHeaderState;
    account: IAccountState;
}

const rootReducer = combineReducers<IState>({
    environment: environmentReducer,
    header: headerReducer,
    account: accountReducer,
});

const middleware = [thunk, loginMiddleware];

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export type RootState = ReturnType<typeof rootReducer>;

export default store;
