export enum COLORS {
    SYMPHONY_BLUE = '#0070d8',
    SYMPHONY_DARKBLUE = '#005FB8',
    PALE_SKY_BLUE = '#c5e3ff',
    INFORMATION_GREY = ' #5d5d5d',
    LIGHT_GREY = '#d8d8d8',
    ICE_GREY = '#eff4f8',
    LIGHT_GREEN = '#D7ECEA',
    GREEN = '#2E7D32',
    LIGHT_YELLOW = '#FEF2CF',
    ORANGE = '#ED6C02',
    LIGHT_RED = '#F7CCCD',
    RED = '#D32F2F',
}
