const globalStyles = {
    generalLoading: {
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
    },
    buttonsRow: {
        '& button': {
            height: '37px',
            mt: 1,
            verticalAlign: 'normal',
        },
        display: 'flex',
        alignItems: 'top',
    },
    verticalBar: {
        borderLeft: '1px solid',
        borderLeftColor: 'secondary.light',
        height: '25px',
        margin: '6px 0 6px 12px',
    },
} as const;

export default globalStyles;
