import LogoutButton from 'components/account/LogoutButton';
import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import VerticalBar from 'components/input/VerticalBar';

const styles = {
    loginStyle: {
        fontSize: '14px',
        pr: 1,
        paddingTop: '4px',
    },
    userNameLabel: {
        px: 1,
        fontWeight: 500,
        color: 'secondary.dark',
        letterSpacing: '0px',
    },
    userName: {
        color: 'secondary.dark',
    },
} as const;

const Login = () => {
    const currentUser = useSelector((state: IState) => state.account.currentUser);
    return (
        <Grid sx={styles.loginStyle} item spacing={0} container justifyContent="flex-end" alignItems="center">
            <Typography sx={styles.userNameLabel}>Member:</Typography>
            <Box component="span" sx={styles.userName} data-cy="logged-in-as">
                {currentUser.firstName && currentUser.lastName ? `${currentUser.firstName} ${currentUser.lastName}` : currentUser.email}
            </Box>
            <VerticalBar />
            <LogoutButton></LogoutButton>
        </Grid>
    );
};

export default Login;
