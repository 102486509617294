import { IAuthenticationPageContent } from 'backend/types/authenticationPageContent';
import { IPageContent } from 'backend/types/pageContent';

export const INITIAL_LOAD_RECEIVED = 'INITIAL_LOAD_RECEIVED';
export type INITIAL_LOAD_RECEIVED = typeof INITIAL_LOAD_RECEIVED;

export const INITIAL_AUTHENTICATION_PAGE_LOAD_RECEIVED = 'INITIAL_AUTHENTICATION_PAGE_LOAD_RECEIVED';
export type INITIAL_AUTHENTICATION_PAGE_LOAD_RECEIVED = typeof INITIAL_AUTHENTICATION_PAGE_LOAD_RECEIVED;

export const TOGGLE_LOADING_ACTION = 'TOGGLE_LOADING_ACTION';
export type TOGGLE_LOADING_ACTION = typeof TOGGLE_LOADING_ACTION;

export const CARE_SITE_RESOLVED = 'CARE_SITE_RESOLVED';
export type CARE_SITE_RESOLVED = typeof CARE_SITE_RESOLVED;

export interface IInitialLoadReceivedAction {
    type: INITIAL_LOAD_RECEIVED;
    pageContent: IPageContent;
}

export interface IToggleLoadingAction {
    type: TOGGLE_LOADING_ACTION;
    isLoading: boolean;
}

export interface IInitialAuthenticationPageLoadReceivedAction {
    type: INITIAL_AUTHENTICATION_PAGE_LOAD_RECEIVED;
    authenticationPageContent: IAuthenticationPageContent;
}

export const initialAuthenticationPageLoadReceived = (authenticationPageContent: IAuthenticationPageContent): IInitialAuthenticationPageLoadReceivedAction => {
    return {
        type: INITIAL_AUTHENTICATION_PAGE_LOAD_RECEIVED,
        authenticationPageContent: authenticationPageContent as IAuthenticationPageContent,
    };
};

export interface ICareSiteResolvedAction {
    type: CARE_SITE_RESOLVED;
    careSiteExternalId: string;
}

export const careSiteResolved = (careSiteExternalId: string): ICareSiteResolvedAction => {
    return {
        type: CARE_SITE_RESOLVED,
        careSiteExternalId: careSiteExternalId,
    };
};

export const initialLoadReceived = (pageContent: IPageContent): IInitialLoadReceivedAction => {
    return {
        type: INITIAL_LOAD_RECEIVED,
        pageContent: pageContent as IPageContent,
    };
};

export const toggleLoadingAction = (isLoading: boolean): IToggleLoadingAction => {
    return {
        type: TOGGLE_LOADING_ACTION,
        isLoading: isLoading,
    };
};

export type IEnvironmentAction = IInitialLoadReceivedAction | IInitialAuthenticationPageLoadReceivedAction | IToggleLoadingAction | ICareSiteResolvedAction;
