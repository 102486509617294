import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

interface IIconWithTextProps {
    icon: ReactElement;
    text?: string;
}

const IconWithText = ({ icon, text }: IIconWithTextProps) => {
    return (
        <Box component="span" display="flex" alignItems="center" flexWrap="wrap">
            {icon}{' '}
            <Typography component="span" variant="inherit" pl={1}>
                {text}
            </Typography>
        </Box>
    );
};

export default IconWithText;
