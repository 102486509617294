interface IAppConfig {
    readonly apiUrl: string;
}

let apiUrl = process.env.REACT_APP_API_URL as string;

// for CORS when local development and http protocol
if (process.env.NODE_ENV === 'development' && window.location.protocol === 'http:') {
    const appSubDomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    if (appSubDomain) {
        apiUrl = apiUrl.replace('localhost', `${appSubDomain}.localhost`);
    }
}

const appConfig: IAppConfig = {
    apiUrl: apiUrl,
};

export default appConfig;
