import { IAccountAttribute, IAuthUser, IMemberDetails } from './types';

export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';
export const LOGIN_MFA = 'LOGIN_MFA';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const MEMBER_NOT_REGISTERED = 'MEMBER_NOT_REGISTERED';
export const FORGOT_EMAIL_SUCCESS = 'FORGOT_EMAIL_SUCCESS';

export type CONFIRM_ACCOUNT = typeof CONFIRM_ACCOUNT;
export type CONFIRM_ACCOUNT_SUCCESS = typeof CONFIRM_ACCOUNT_SUCCESS;
export type LOGIN_MFA = typeof LOGIN_MFA;
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS;
export type LOGOUT_SUCCESS = typeof LOGOUT_SUCCESS;
export type FORGOT_PASSWORD_SUCCESS = typeof FORGOT_PASSWORD_SUCCESS;
export type CHANGE_PASSWORD = typeof CHANGE_PASSWORD;
export type CHANGE_PASSWORD_SUCCESS = typeof CHANGE_PASSWORD_SUCCESS;
export type MEMBER_NOT_REGISTERED = typeof MEMBER_NOT_REGISTERED;
export type FORGOT_EMAIL_SUCCESS = typeof FORGOT_EMAIL_SUCCESS;

export interface IConfirmAccountAction {
    type: CONFIRM_ACCOUNT;
    email: string;
}

export interface IConfirmAccountSuccessAction {
    type: CONFIRM_ACCOUNT_SUCCESS;
    email: string;
}

export interface ILoginMfaAction {
    type: LOGIN_MFA;
    email: string;
    token: string;
}

export interface ILoginSuccessAction {
    type: LOGIN_SUCCESS;
    user: IAuthUser;
}

export interface ILogoutSuccessAction {
    type: LOGOUT_SUCCESS;
}

export interface IForgotPasswordSuccessAction {
    type: FORGOT_PASSWORD_SUCCESS;
    email: string;
}

export interface IChangePasswordAction {
    type: CHANGE_PASSWORD;
    email: string;
}

export interface IChangePasswordSuccessAction {
    type: CHANGE_PASSWORD_SUCCESS;
    email: string;
}

export interface IMemberNotRegisteredAction {
    type: MEMBER_NOT_REGISTERED;
    memberDetails: IMemberDetails;
}

export interface IForgotEmailSuccessAction {
    type: FORGOT_EMAIL_SUCCESS;
    email: string;
}

export function confirmAccount(email: string): IConfirmAccountAction {
    return {
        type: CONFIRM_ACCOUNT,
        email: email,
    };
}

export function confirmAccountSuccess(email: string): IConfirmAccountSuccessAction {
    return {
        type: CONFIRM_ACCOUNT_SUCCESS,
        email: email,
    };
}

export function authMfa(email: string, token: string): ILoginMfaAction {
    return {
        type: LOGIN_MFA,
        email: email,
        token: token,
    };
}

export function loginSuccess(user: IAuthUser): ILoginSuccessAction {
    return {
        type: LOGIN_SUCCESS,
        user: user,
    };
}

export function logoutSuccess(): ILogoutSuccessAction {
    return {
        type: LOGOUT_SUCCESS,
    };
}

export function forgotPasswordSuccess(email: string): IForgotPasswordSuccessAction {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        email: email,
    };
}

export function changePassword(email: string): IChangePasswordAction {
    return {
        type: CHANGE_PASSWORD,
        email: email,
    };
}

export function changePasswordSuccess(email: string): IChangePasswordSuccessAction {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        email: email,
    };
}

export function memberNotRegistered(
    givenName: string,
    familyName: string,
    dateOfBirth: Date | null,
    memberCardSuffix: string | null,
    socialSecurityNumberSuffix: string | null,
    attributes?: Array<IAccountAttribute>
): IMemberNotRegisteredAction {
    return {
        type: MEMBER_NOT_REGISTERED,
        memberDetails: {
            givenName,
            familyName,
            dateOfBirth,
            memberCardSuffix,
            socialSecurityNumberSuffix,
            attributes,
        },
    };
}

export function forgotEmailSuccess(email: string): IForgotEmailSuccessAction {
    return {
        type: FORGOT_EMAIL_SUCCESS,
        email: email,
    };
}

export type IAccountActions =
    | IConfirmAccountAction
    | IConfirmAccountSuccessAction
    | ILoginMfaAction
    | ILoginSuccessAction
    | ILogoutSuccessAction
    | IForgotPasswordSuccessAction
    | IChangePasswordSuccessAction
    | IChangePasswordAction
    | IMemberNotRegisteredAction
    | IForgotEmailSuccessAction;
