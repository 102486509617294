export enum AccountResultStatusCode {
    Ok = 'OK',
    Failed = 'FAILED',
    Error = 'ERROR',
}

export interface IAccountResult {
    status: AccountResultStatusCode;
    errorMessage?: string;
}

export enum LoginAccountResultStatusCode {
    Ok = 'OK',
    Failed = 'FAILED',
    Error = 'ERROR',
    UserStatusUnconfirmed = 'USER_STATUS_UNCONFIRMED',
    UserStatusResetRequired = 'USER_STATUS_RESET_REQUIRED',
    UserStatusForceChangePassword = 'USER_STATUS_FORCE_CHANGE_PASSWORD',
    MfaRequired = 'MFA_REQUIRED',
    MfaSetup = 'MFA_SETUP',
}

export interface ILoginAccountResult {
    status: LoginAccountResultStatusCode;
    errorMessage?: string;
    token?: string;
}

export enum ForgotPasswordStatusCode {
    Ok = 'OK',
    Failed = 'FAILED',
    Error = 'ERROR',
    UserStatusUnconfirmed = 'USER_STATUS_UNCONFIRMED',
}

export interface IForgotPasswordAccountResult {
    status: ForgotPasswordStatusCode;
    errorMessage?: string;
    token?: string;
}

export enum CreateUserStatusCode {
    Ok = 'OK',
    Failed = 'FAILED',
    Error = 'ERROR',
    ExistingUnconfirmed = 'EXISTING_UNCONFIRMED',
    ExistingConfirmed = 'EXISTING_CONFIRMED',
    ExistingDifferentMember = 'EXISTING_DIFFERENT_MEMBER',
    ExistingDifferentEmail = 'EXISTING_DIFFERENT_EMAIL',
}

export interface ICreateUserResult {
    status: CreateUserStatusCode;
    errorMessage?: string;
}

export enum MfaResultStatusCode {
    Ok = 'OK',
    Failed = 'FAILED',
    Error = 'ERROR',
}

export interface IMfaResult {
    status: MfaResultStatusCode;
    errorMessage?: string;
    secretCode?: string;
    token?: string;
}

export enum ForgotEmailStatusCode {
    Ok = 'OK',
    Failed = 'FAILED',
    Error = 'ERROR',
    NotRegistered = 'NOT_REGISTERED',
}

export interface IForgotEmailResult {
    status: ForgotEmailStatusCode;
    errorMessage?: string;
    email?: string;
}
