import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from 'store/account/middleware';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { useHistory } from 'react-router-dom';

const styles = {
    signOutButton: {
        marginLeft: '4px',
        letterSpacing: '0px',
    },
} as const;

const LogoutButton = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <Button sx={styles.signOutButton} variant="text" startIcon={<ExitToApp />} onClick={() => dispatch(logout(history))}>
            Sign out
        </Button>
    );
};

export default LogoutButton;
