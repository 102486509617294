import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import AuthenticationRoute from './AuthenticationRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
    const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
    const Home = React.lazy(() => import('../pages/home'));
    const Coverage = React.lazy(() => import('../pages/coverage'));
    const Authorizations = React.lazy(() => import('../pages/authorizations'));
    const Claims = React.lazy(() => import('../pages/claims'));
    const Resources = React.lazy(() => import('../pages/resources'));
    const RegisterAccount = React.lazy(() => import('../pages/account/register'));
    const RegisterMfa = React.lazy(() => import('../pages/account/registerMfa'));
    const ConfirmAccount = React.lazy(() => import('../pages/account/confirm'));
    const Login = React.lazy(() => import('../pages/account/login'));
    const LoginMfa = React.lazy(() => import('../pages/account/loginMfa'));
    const ResetPassword = React.lazy(() => import('../pages/account/resetPassword'));
    const ForgotPassword = React.lazy(() => import('../pages/account/forgotPassword'));
    const ForgotEmail = React.lazy(() => import('../pages/account/forgotEmail'));
    const ChangePassword = React.lazy(() => import('../pages/account/changePassword'));
    const NotFound = React.lazy(() => import('../pages/notFound'));

    return (
        <>
            <BrowserRouter>
                <React.Suspense fallback={loading()}>
                    <Switch>
                        <PrivateRoute path="/" targetComponent={Home} exact />
                        <PrivateRoute path="/coverage/" targetComponent={Coverage} exact={false} />
                        <PrivateRoute path="/authorizations/" targetComponent={Authorizations} exact={false} />
                        <PrivateRoute path="/claims/" targetComponent={Claims} exact={false} />
                        <PrivateRoute path="/resources/" targetComponent={Resources} exact={false} />
                        <AuthenticationRoute path="/account/change-password" targetComponent={ChangePassword} exact={false} />
                        <AuthenticationRoute path="/account/register" targetComponent={RegisterAccount} exact={false} />
                        <AuthenticationRoute path="/account/register-mfa" targetComponent={RegisterMfa} exact={false} />
                        <AuthenticationRoute path="/account/confirm" targetComponent={ConfirmAccount} exact={false} />
                        <AuthenticationRoute path="/account/login" targetComponent={Login} exact={false} />
                        <AuthenticationRoute path="/account/login-mfa" targetComponent={LoginMfa} exact={false} />
                        <AuthenticationRoute path="/account/reset-password" targetComponent={ResetPassword} exact={false} />
                        <AuthenticationRoute path="/account/forgot-password" targetComponent={ForgotPassword} exact={false} />
                        <AuthenticationRoute path="/account/forgot-email" targetComponent={ForgotEmail} exact={false} />
                        <PublicRoute path="/not-found" targetComponent={NotFound} exact={false} />
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        </>
    );
};

export default Routes;
