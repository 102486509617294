import { IEnvironmentState } from './types';
import { IEnvironmentAction, INITIAL_LOAD_RECEIVED, INITIAL_AUTHENTICATION_PAGE_LOAD_RECEIVED, TOGGLE_LOADING_ACTION, CARE_SITE_RESOLVED } from './action';
import { IPageContent } from 'backend/types/pageContent';
import { IAuthenticationPageContent } from 'backend/types/authenticationPageContent';

export const initialEnvironmentState: IEnvironmentState = {
    pageContent: {
        images: {
            logo: '',
            welcome: '',
        },
        widgetsHtml: [
            { size: 7, content: '' },
            { size: 7, content: '' },
            { size: 5, content: '' },
            { size: 5, content: '' },
        ],
    } as IPageContent,
    authenticationPageContent: {
        images: {
            logo: '',
        },
        sideInformation: {
            contactPhone: '',
            descriptionHtml: '',
            workHours: '',
        },
    },
    careSiteExternalId: undefined,
    isLoading: 0,
};

const environmentReducer = (state: IEnvironmentState = initialEnvironmentState, action: IEnvironmentAction): IEnvironmentState => {
    switch (action.type) {
        case INITIAL_LOAD_RECEIVED:
            return {
                ...state,
                pageContent: action.pageContent as IPageContent,
            };
        case INITIAL_AUTHENTICATION_PAGE_LOAD_RECEIVED:
            return {
                ...state,
                authenticationPageContent: action.authenticationPageContent as IAuthenticationPageContent,
            };
        case TOGGLE_LOADING_ACTION: {
            const isLoading: number = state.isLoading + (action.isLoading ? 1 : -1);
            return {
                ...state,
                isLoading: isLoading,
            };
        }
        case CARE_SITE_RESOLVED: {
            return {
                ...state,
                careSiteExternalId: action.careSiteExternalId,
            };
        }
        default:
            return state;
    }
};

export default environmentReducer;
