import React, { ReactNode } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import SideInformationPanel from 'components/authentication/SideInformationPanel';

interface IAuthenticationPageWrapperProps {
    children: ReactNode;
}

const styles = {
    container: {
        height: '100vh',
    },
    contentContainer: {
        margin: '0 auto',
        height: '100%',
    },
    contentInnerContainer: {
        margin: 'auto auto',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column',
    },
    gridDimensions: {
        margin: '0 2.5%',
    },
} as const;

const AuthenticationPageWrapper = ({ children }: IAuthenticationPageWrapperProps) => {
    const authenticationPageContent = useSelector((s: IState) => s.environment.authenticationPageContent);
    const careSiteExternalId = useSelector((s: IState) => s.environment.careSiteExternalId);
    return (
        <Grid container sx={styles.container} spacing={0}>
            <Grid item xs={12} md={4}>
                <SideInformationPanel authenticationPageContent={authenticationPageContent} careSiteExternalId={careSiteExternalId} />
            </Grid>
            <Grid item xs={12} md={8}>
                <Box component="div" sx={styles.contentContainer}>
                    <Box component="div" sx={styles.contentInnerContainer}>
                        <Box sx={styles.gridDimensions}>{children}</Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AuthenticationPageWrapper;
