import React, { useLayoutEffect, useState } from 'react';
import { Typography, Tab, Tabs, Grid, LinearProgress, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from 'store';
import { selectTab } from 'store/header/actions';
import Login from './login';
import globalStyles from 'config/cssHooks';
import { Link as RouterLink } from 'react-router-dom';
import { getImagePath, handleOnImageError } from 'util/helpers/imageHelpers';

const styles = {
    logoContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        ml: 2,
    },
    logoText: {
        fontWeight: 600,
        fontSize: '18px',
    },
    removeDecorations: {
        textDecoration: 'none',
        color: 'inherit',
        textTransform: 'capitalize',
        paddingTop: '24px',
    },
    ribbonHeight: {
        height: '32px',
    },
} as const;

const Header = () => {
    const largeWindowWidth = 1279;
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const careSiteExternalId = useSelector((state: IState) => state.environment.pageContent.careSiteExternalId);
    const logo = useSelector((state: IState) => state.environment.pageContent.images.logo);
    const selectedTabNo = useSelector((state: IState) => state.header.selectedTabNo);
    const dispatch = useDispatch();
    const isLoading = useSelector((state: IState) => state.environment.isLoading) > 0;

    useLayoutEffect(() => {
        function onResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    const handleChange = (_: React.ChangeEvent<unknown>, activeTabNo: number) => {
        dispatch(selectTab(activeTabNo));
    };

    const logoContent = () => {
        return (
            <Box component="div" sx={styles.logoContainer}>
                <img height={styles.ribbonHeight.height} src={getImagePath(logo, careSiteExternalId)} onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleOnImageError(e, logo)} />
                <Typography sx={styles.logoText} px={2}>
                    Member portal
                </Typography>
            </Box>
        );
    };

    const tabsContent = () => {
        return (
            <>
                <Tabs textColor="inherit" value={selectedTabNo} onChange={handleChange} aria-label="tabs as pages or links to pages" variant="scrollable">
                    <Tab component={RouterLink} label="Home" to="/" sx={styles.removeDecorations} data-cy="home-tab" />
                    <Tab component={RouterLink} label="Coverage" to="/coverage/" sx={styles.removeDecorations} data-cy="coverage-tab" />
                    <Tab component={RouterLink} label="Authorizations" to="/authorizations/" sx={styles.removeDecorations} data-cy="authorizations-tab" />
                    <Tab component={RouterLink} label="Claims" to="/claims/" sx={styles.removeDecorations} data-cy="claims-tab" />
                    <Tab component={RouterLink} label="Resources" to="/resources/" sx={styles.removeDecorations} data-cy="resources-tab" />
                </Tabs>
            </>
        );
    };

    const getExtraLargeScreenLayout = () => {
        return (
            <Grid container spacing={0}>
                <Grid item container py={2} spacing={0} xs={12} sm={3} md={3} lg={3} xl={3} alignItems="center">
                    {logoContent()}
                </Grid>
                <Grid item container spacing={0} xs={12} sm={9} md={9} lg={6} xl={6} justifyContent="center">
                    {tabsContent()}
                </Grid>
                <Grid item container spacing={0} xs={12} sm={12} md={12} lg={3} xl={3}>
                    <Login />
                </Grid>
            </Grid>
        );
    };

    const getLargeToSmallScreenLayout = () => {
        return (
            <Grid container spacing={0}>
                <Grid item container py={2} spacing={0} xs={12} sm={6} md={6} lg={2} xl={2} alignItems="center">
                    {logoContent()}
                </Grid>
                <Grid item container spacing={0} xs={12} sm={6} md={6} lg={3} xl={3}>
                    <Login />
                </Grid>
                <Grid item container spacing={0} xs={12} sm={12} md={12} lg={7} xl={7} justifyContent="center">
                    {tabsContent()}
                </Grid>
            </Grid>
        );
    };
    return (
        <>
            {isLoading ? <LinearProgress sx={globalStyles.generalLoading} /> : null}
            {windowWidth > largeWindowWidth ? getExtraLargeScreenLayout() : getLargeToSmallScreenLayout()}
        </>
    );
};

export default Header;
