import React from 'react';
import Routes from './routes';
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { CookiesProvider } from 'react-cookie';
import theme from 'theme';
import { ToastContainer } from 'react-toastify';

const App = () => {
    return (
        <CookiesProvider>
            <ThemeProvider theme={theme}>
                <ToastContainer />
                <Routes />
            </ThemeProvider>
        </CookiesProvider>
    );
};

export default App;
