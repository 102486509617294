import { handleExpiredSession } from 'store/account/middleware';
import { InMemoryCache, ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { toggleLoadingAction } from 'store/environment/action';
import store from 'store';
import appConfig from 'config/appConfig';
import { dateFormatLink } from './helpers/dateFormatLink';

const toggleLoadingLink = new ApolloLink((operation, forward) => {
    store.dispatch(toggleLoadingAction(true));
    return forward(operation).map((result) => {
        store.dispatch(toggleLoadingAction(false));
        return result;
    });
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError && 'statusCode' in networkError) {
        if (401 === networkError.statusCode) {
            handleExpiredSession(store.dispatch);
        }
    }

    if (graphQLErrors) {
        const unauthenticated = graphQLErrors.find((s) => s.extensions?.code == 'NOT_AUTHENTICATED');
        if (unauthenticated) {
            handleExpiredSession(store.dispatch);
        }
    }

    store.dispatch(toggleLoadingAction(false));
    console.log('Error from ApolloClient:', networkError, graphQLErrors);
});

const baseLink = new HttpLink({
    uri: appConfig.apiUrl + '/api/graphql/',
    credentials: 'include',
});

const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, toggleLoadingLink, dateFormatLink, baseLink]),
});

export default apolloClient;
